:root {
  --main-container-height: 85vh;
  --code-editor-height-ratio: .65; 
}

.center {
  text-align: center;
}

.level-headings {
  margin-bottom: 0;
  padding-bottom: 0%;
  color: white;
}

.console-log {
  background-color: #23262E;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  overflow-y: scroll;
  white-space: pre-wrap;
  height: 30.5vh;
  border-radius: 10px;
}

.code-editor {
  width: 75%;
  margin: auto;
}

.center-text{
  text-align: center;
}

.img-container {
  width: 100%;
  height: 45vh;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border: 8px solid #327C81;
  border-image: 
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 0H8v4H4v4H0v8h4v4h4v4h8v-4h4v-4h4V8h-4V4h-4V0zm0 4v4h4v8h-4v4H8v-4H4V8h4V4h8z' fill='%23327C81'/%3E%3C/svg%3E")
    8 stretch;
  margin-top: 12px;
  margin-bottom: 12px;
}

.logo-color {
  color: #83c5be;
}

.logo-color-dark {
  color: #327C81;
}

.logo-orange {
  color:#FD5C51;
}

.task-background {
  background-image: url('../public/img/space.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  flex-wrap: wrap;
  display: flex;
  overflow: hidden;
}

.modules-background {
  background-color: black;
}

.white-background {
  background-color: white;
  border-radius: 10px;
  padding: 1%;
}

.textBoxBiggerMargin {
  margin: 1% 15% 1% 15%;
}

.textBoxSmallerMargin {
  margin: 1%;
}

.level-progress {
  display: flex;
  align-items: center;
  margin-bottom: 1%;
}

.circle {
  border: 2px solid #327C81;
  border-radius: 50%;
}

.filled {
  background-color: #327C81;
}

.open {
  border: 2px solid #327C81;
}

.line {
  height: 2px;
  width: 2vw; 
  background-color: #327C81;
  margin: 0 0px;
}

.level-progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0%;
  padding-top: 0%;
  margin-bottom: 15px;
  width: 100vw;
}

.custom-btn {
  background-color: #327C81 !important;
  border: 2px solid #327C81 !important; 
  transition: background-color 0.3s, border-color 0.3s; 
}

.console-btn {
  background-color: #327C81 !important;
  border: 2px solid #327C81 !important; 
  transition: background-color 0.3s, border-color 0.3s; 
  width: 49%;
}

.custom-btn:hover {
  background-color: #285A5C !important;
  border-color: #285A5C !important;
}

.custom-btn-orange {
  background-color: #FD5C51 !important;
  border: 2px solid #FD5C51 !important;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-btn-orange:hover {
  background-color: #72302C !important;
  border-color: #72302C !important;
}

.custom-btn-transparent {
  background-color: transparent !important;
  border: 2px solid #327C81 !important;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 50% !important;
}

.custom-btn-transparent:hover {
  background-color: #285A5C !important;
  border-color: #285A5C !important;
}

.custom-btn-round {
  background-color: #327C81 !important;
  border: 2px solid #327C81 !important;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 50% !important;
}

.custom-btn-round:hover {
  background-color: #285A5C !important;
  border-color: #285A5C !important;
}

.smaller-margin-btn {
  margin: .5rem;
}


.instructions-btn {
  padding: .5rem;
  border-radius: .3rem;
}

.console-text {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.module-card {
  background-color: #327C81 !important;
}

.card-img {
  transition: filter 0.5s ease;
  border-radius: 0% !important;
}

.card-img-overlay {
  position: relative;
}

.overlay-text {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.module-card:hover { 
  cursor: pointer;
}

.module-card:hover .card-img {
  filter: brightness(20%);
}

.module-card:hover .overlay-text {
  opacity: 1;
}

.pixel-font {
  font-family: 'Onesize', sans-serif;
}

.torch-left {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 25vh;
}

.logo {
  top: 0;
  left: 0;
  position: fixed;
  width: 25vh;
}

.torch-right {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 25vh;
}

.custom-z-index{
  z-index: 10;
}

.code-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.storyline-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;
}

.consoleButtonContainer {
  width: 45vw;
  display: flex;
  flex-direction: column;
  padding-top: 1rem
}

.consoleButtonContainerFullHeight {
  height: var(--main-container-height);
}

.consoleButtonContainerPartHeight {
  height: calc((1 - var(--code-editor-height-ratio)) * var(--main-container-height));
}

.codeEditorButtonContainer {
  display: flex;
  flex-direction: column;
  width: 45vw;
  padding-top: 1rem
}

/* May need to change id=codeMirror height settings to prevent issues if these heights are changed */
.codeEditorButtonContainerFullHeight {
  height: var(--main-container-height);
}

/* May need to change id=codeMirror height settings to prevent issues if these heights are changed */
.codeEditorButtonContainerPartHeight {
  height: calc(var(--code-editor-height-ratio) * var(--main-container-height));
}

.mainContainer{
  height: var(--main-container-height);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.storylineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.codeContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: var(--main-container-height);
  align-content: center;
}

.bottomContainer{
  height: 15vh;
  width: 100vw;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  backdrop-filter: blur(3px);
  z-index: 100;

}

/* Modal content */
.hintModal {
  width: 50vw; 
  height: 50vh; 
  background-color: #327C81; 
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 1.5rem;
}

.completionModal {
  width: 50vw; 
  height: 60vh; 
  background-color: #ffffff; 
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 1.5rem;
}

.modalButton {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: -50px;
  right: -50px;
  font-size: 2rem;
  padding: .5rem;
  border-radius: 50%;
  border: 2px solid white;
  
}

.hintContent {
  background-color: #23262E;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  overflow-y: auto;
  white-space: pre;
  height: 100%;
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  user-select: text;
}

.taskCompletionContent {
  font-family: 'Onesize', sans-serif;
  text-align: center;
  padding-top: 1rem;
  background-color: #ffffff;
  color: var(--bs-body-color);
  overflow-y: auto;
  overflow-x: auto;
  white-space: pre;
  height: 100%;
  width: 100%;
  white-space: pre-wrap
}

.modalButton:hover {
  background-color: #dc3545;
  color: white;
}

.modalButton p {
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  p {
    font-size: 12px; 
  }
  .line {
    width: 1vw;
  }
  .textBoxBiggerMargin {
    margin: 1% 5% 1% 5%;
  }  
}

@media (max-width: 770px) {
  p {
    font-size: 10px; 
  }
  .line {
    width: .5vw;
  }

  .card-body{
    padding: .5rem !important; 
  }

  .card-module-container {
    flex-direction: column;
  }
  .module-card {
    margin-bottom: 2REM;
  }

  .overlay-text p {
    font-size: 1.2REM; 
  }

  .card-title.h5{
    font-size: 1.5rem; 
  }
  .torch {
    display: none;
  }
  .logo {
    display: none;
  }
  .textBoxBiggerMargin {
    margin: 1% 1% 1% 1%;
  }  
}

@media (max-width: 945px) {
  .level-progress {
    display: none;
  }
}

@media (min-width: 945px) {
  .previous-btn {
    display: none !important;
  }
}

@media (max-width: 660px) {
  .smaller-margin-btn {
    margin: .1rem;
  }
}

@media (max-width: 465px) {
  .level-progress-container {
    flex-direction: column;
  }
}

@media (max-height: 600px) {
  .torch-left {
    display: none;
  }
  .torch-right {
    display: none;
  }
}